import React, { Component, Fragment } from 'react'
import { Badge, Text, Row, settings as s } from 'App/UI'
const ClassificationBadge = ({ color, Icon, stat }) => (
  <Badge bg={color}>
    <Row y w="62.5" h="25" space="around">
      <Icon color="white" size={25} />
      <Text.title size={4}>{Math.round(stat)}</Text.title>
    </Row>
  </Badge>
)
export default ClassificationBadge
