import React, { Component, Fragment as _, useState, useEffect } from 'react'
import { createSelector } from 'reselect'
import { css } from 'react-emotion'
import { connect } from 'react-redux'
import { RingLoader } from 'react-spinners'
import { indexArrayBy, capitalize } from 'utils'
import { Box, Col, Text, Row, settings as s } from 'App/UI'
import { selectQuery } from 'App/state'
import View from 'App/shared/View'
import { BlockIcon, HamburgerIcon } from 'App/shared/Icons'
import {
  loadCustomerAnalysis,
  loadLocationDetails,
  listenForInteractions,
  declineAppreciationAction,
  sendAppreciationText
} from './state'
import CustomerTinder from './components/CustomerTinder'
import CustomerList from './components/CustomerList'
import { CustomerTutorial, TinderTutorial } from './components/Tutorials'

const views = {
  customerList: 'customerList',
  customerTinder: 'customerTinder'
}

const tutorials = {
  tinder: {
    boolName: 'showTinderTutorial'
  },
  customer: {
    boolName: 'showCustomerTutorial'
  }
}
const ConsumerAnalysis = props => {
  const [state, updateState] = useState({
    currentView: views.customerList,
    showTinderTutorial: false,
    showCustomerTutorial: false,
    loadingAnalysis: true,
    customerStartIndex: 0
  })

  const setState = newState =>
    updateState({
      ...state,
      ...newState
    })

  useEffect(() => {
    props
      .loadCustomerAnalysis({
        period: props.period,
        spendThreshold: props.spendThreshold,
        repeatThreshold: props.repeatThreshold
      })
      .then(response => {
        setState({
          loadingAnalysis: false
        })
      })
    props.loadLocationDetails()
    props.listenForInteractions(props.period)
  }, [])

  const changeView = currentView =>
    setState({
      currentView
    })

  const goToCustomer = customerStartIndex =>
    setState({ currentView: views.customerTinder, customerStartIndex })

  const closeTutorial = config => () =>
    setState({
      [config.boolName]: false
    })

  const sendAppreciationText = ({
    customer,
    textMessage,
    includedPromo,
    interactionToUpdate
  }) => {
    return props.sendAppreciationText({
      period: props.period,
      textMessage,
      includedPromo,
      consumerId: customer.id,
      interactionToUpdate
    })
  }

  const declineAppreciationAction = ({ customer }) => {
    return props.declineAppreciationAction({
      period: props.period,
      consumerId: customer.id
    })
  }

  const isListView = state.currentView === views.customerList
  const isTinderView = state.currentView === views.customerTinder

  return (
    <Box>
      <Row space="between" y px={3} pt={3}>
        <Col textAlign="left" y maxWidth="50%">
          <Text.title color={'white'}>{props.locationDetails.name}</Text.title>
          <Text
            color={'white'}
            css={css`
              text-transform: capitalize;
            `}
          >
            {props.period.month}, {props.period.year}
          </Text>
        </Col>
        <Row y w="110px" space="between">
          <Text.title color="white">VIEW</Text.title>
          <Box onClick={() => changeView(views.customerList)} cursor="pointer">
            <HamburgerIcon
              size={20}
              color={isListView ? s.colors.purple : 'white'}
            />
          </Box>
          <Box
            onClick={() => changeView(views.customerTinder)}
            cursor="pointer"
          >
            <BlockIcon
              size={20}
              color={isTinderView ? s.colors.purple : 'white'}
            />
          </Box>
        </Row>
      </Row>
      <Box pt={4}>
        <_>
          {state.loadingAnalysis ? (
            <Row x pt={4}>
              <RingLoader
                size={150}
                color={s.colors.aqua}
                loading={state.loadingAnalysis}
              />
            </Row>
          ) : (
            <_>
              {isListView && (
                <Box px={3} maxWidth="400px" m="0 auto">
                  <CustomerTutorial
                    isOpen={state.showCustomerTutorial}
                    onRequestClose={closeTutorial(tutorials.customer)}
                  />
                  <CustomerList
                    goToCustomer={goToCustomer}
                    customers={props.customerAnalysis.list}
                    interactions={props.interactions}
                  />
                </Box>
              )}

              {isTinderView && (
                <Row x px={3} pt={3}>
                  <TinderTutorial
                    isOpen={state.showTinderTutorial}
                    onRequestClose={closeTutorial(tutorials.tinder)}
                  />
                  <CustomerTinder
                    startIndex={state.customerStartIndex}
                    onApproveAction={sendAppreciationText}
                    onDeclineAction={declineAppreciationAction}
                    customers={props.customerAnalysis.list}
                    interactions={props.interactions}
                    interactionConfig={props.interactionConfig}
                  />
                </Row>
              )}
            </_>
          )}
        </_>
      </Box>
    </Box>
  )
}

const selectQueryParams = (state, { match }) => match.params
const selectEntitiesNamed = name => state => state.entities[name]

const selectCustomerAnalysisByLocId = createSelector(
  state => state.customerAnalysis,
  selectQueryParams,
  (customerAnalysisMap, { locId }) => {
    const customerAnalysis = customerAnalysisMap[locId] || {
      totals: {},
      list: []
    }
    const totalMap = customerAnalysis.totals
    delete totalMap.critics
    return {
      list: customerAnalysis.list.filter(c =>
        c.classifications.some(cl => cl.type !== 'critic')
      ),
      totals: Object.keys(totalMap).map(key => ({
        type: key,
        ...totalMap[key]
      }))
    }
  }
)

const selectInteractions = createSelector(
  selectEntitiesNamed('interactions'),
  (state, props) =>
    state.customerInteractions[selectQueryParams(state, props).locId],
  (interactionEntities, interactionIds) =>
    (interactionIds || []).map(id => interactionEntities[id])
)

const selectPeriod = createSelector(
  selectQuery,
  vars => ({
    type: vars.type,
    year: vars.year,
    month: vars.month
  })
)

const selectSpendThreshold = createSelector(
  selectQuery,
  vars => vars.spendThreshold || 50
)

const selectRepeatThreshold = createSelector(
  selectQuery,
  vars => vars.repeatThreshold || 2
)

const selectLocation = createSelector(
  selectEntitiesNamed('locations'),
  selectQueryParams,
  (locations, { locId }) => locations[locId]
)

const select = (state, props) => {
  const locationDetails = selectLocation(state, props) || {}
  const interactionConfig = locationDetails.interactionConfig || {}
  const period = selectPeriod(state, props)
  return {
    locationDetails,
    interactionConfig: {
      ...(interactionConfig.appreciation || {}),
      pointOfContact: interactionConfig.pointOfContact,
      locationName: locationDetails.name,
      period: `in ${capitalize(period.month)}`
    },
    customerAnalysis: selectCustomerAnalysisByLocId(state, props),
    interactions: indexArrayBy('consumerId')({
      entities: selectInteractions(state, props),
      transform: interaction => ({
        ...interaction,
        ...interaction.meta
      })
    }),
    spendThreshold: selectSpendThreshold(state, props),
    repeatThreshold: selectRepeatThreshold(state, props),
    period
  }
}

const actions = (dispatch, props) => {
  const { locId, orgId } = selectQueryParams({}, props)
  return {
    loadCustomerAnalysis: ({ period, spendThreshold, repeatThreshold }) =>
      dispatch(
        loadCustomerAnalysis({
          period,
          spendThreshold,
          repeatThreshold,
          orgId,
          locId
        })
      ),
    loadLocationDetails: () => dispatch(loadLocationDetails({ orgId, locId })),
    declineAppreciationAction: params =>
      declineAppreciationAction({
        locId,
        orgId,
        ...params
      }),
    sendAppreciationText: params =>
      sendAppreciationText({
        locId,
        orgId,
        ...params
      }),
    listenForInteractions: period =>
      dispatch(
        listenForInteractions({
          locId,
          orgId,
          period
        })
      )
  }
}

const ConsumerAnalysisView = connect(
  select,
  actions
)(ConsumerAnalysis)

export default props => (
  <View>
    {({ Nav }) => (
      <_>
        <Nav
          center={
            <Text.title color="white" size={2}>
              Customer Analysis
            </Text.title>
          }
        />
        <ConsumerAnalysisView {...props} />
      </_>
    )}
  </View>
)
