import React from 'react'
import { keyframes } from 'react-emotion'
import { Box } from 'App/UI'
import useWindowSize from 'App/hooks/useWindowSize'
const NavHeight = 70

const retractAnimation = prop => keyframes`
0% {
  ${prop}: 0;
}

90% {
  ${prop}: -${NavHeight}px;
}
`
/**
 * TODO: change which background image is being loaded based on viewport width
 */
const Nav = ({ left, center, right, retract }) => (
  <Box.row
    zIndex="10"
    position="fixed"
    height={NavHeight}
    width="100%"
    px={2}
    justify="space-between"
    align="center"
    backgroundImage={`url(${require('./gradient-long.svg')})`}
    backgroundPosition="top right"
    pt={2}
    top={retract ? `-${NavHeight}px` : '0px'}
    css={`
      animation: ${retract ? retractAnimation('top') : ''} 1.25s ease;
    `}
    transition=".25s"
  >
    <Box.row w={1 / 4}>{left}</Box.row>
    <Box.row w={1 / 2} x>
      {center}
    </Box.row>
    <Box.row w={1 / 4} x="end">
      {left}
    </Box.row>
  </Box.row>
)

export default ({ children }) => {
  const windowSize = useWindowSize()
  const smallView = windowSize.innerHeight <= 700
  const _Nav = smallView ? props => <Nav retract={true} {...props} /> : Nav
  return (
    <Box
      css={`
        padding-top: ${smallView ? 0 : 70}px;
        animation: ${smallView ? retractAnimation('padding-top') : ''} 1.25s
          ease;
        transition: 1.25s;
      `}
      height="100%"
      w="100%"
      background="rgb(8,8,12)"
      background="linear-gradient(180deg, rgba(8,8,12,1) 0%, rgba(91,39,162,1) 50%, rgba(129,71,255,1) 100%)"
    >
      {children({ Nav: _Nav, windowSize })}
    </Box>
  )
}
