"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledSystem = require("styled-system");

var _settings = require("../../settings");

var _utils = require("../../utils");

var _emotion = _interopRequireDefault(require("@rebass/components/emotion"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var num = function num(n) {
  return typeof n === 'number' && !isNaN(n);
};

var px = function px(n) {
  return num(n) ? n + 'px' : n;
};

var size = (0, _styledSystem.style)({
  prop: 'size',
  cssProperty: 'font-size',
  key: 'fontSizes',
  transformValue: px,
  scale: [12, 14, 16, 20, 24, 32, 48, 64, 72].map(function (num) {
    return (0, _utils.pxToEm)(num);
  })
});
var apis = ['space', 'fontFamily', 'lineHeight', 'color', 'textAlign'];
var blacklist = ['size', 'family', 'weight'];

var Base = _emotion.default.apply(void 0, [{
  m: 0,
  extend: (0, _emotion.default)({
    blacklist: blacklist
  })
}, function (p) {
  return {
    fontWeight: p.weight
  };
}, function (p) {
  return {
    fontFamily: p.family
  };
}, size].concat(apis));

var sizeToH = ['h6', 'h6', 'h6', 'h6', 'h5', 'h4', 'h3', 'h2', 'h1'];

var Text = function Text(props) {
  return _react.default.createElement(Base, _extends({}, props, {
    is: "span",
    family: _settings.fonts.text
  }));
};

Text.displayName = 'Title';

Text.title = function (props) {
  return _react.default.createElement(Base, _extends({}, props, {
    extend: num(props.size) ? sizeToH[props.size] : 'span',
    family: _settings.fonts.h
  }));
};

Text.title.displayName = 'Title';

Text.p = function (props) {
  return _react.default.createElement(Base, _extends({}, props, {
    is: "p",
    family: _settings.fonts.text
  }));
};

Text.title.displayName = 'P';
var _default = Text;
exports.default = _default;