"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var ratio = 0.81666;

var Astronaut = function Astronaut(_ref) {
  var _ref$size = _ref.size,
      size = _ref$size === void 0 ? 60 : _ref$size;
  return _react.default.createElement("svg", {
    width: size * ratio,
    height: size,
    viewBox: "0 0 49 60",
    version: "1.1",
    xmlns: "http://www.w3.org/2000/svg"
  }, _react.default.createElement("g", {
    stroke: "none",
    strokeWidth: "1",
    fill: "none",
    fillRule: "evenodd"
  }, _react.default.createElement("g", {
    transform: "translate(-164.000000, -168.000000)",
    fillRule: "nonzero"
  }, _react.default.createElement("g", {
    transform: "translate(148.000000, 148.000000)"
  }, _react.default.createElement("g", {
    transform: "translate(16.000000, 20.000000)"
  }, _react.default.createElement("path", {
    d: "M45.9171094,17.9882812 C45.4316016,17.9882812 45.0382031,17.5947656 45.0382031,17.109375 L45.0382031,9.2578125 C45.0382031,8.77242188 45.4316016,8.37890625 45.9171094,8.37890625 C46.4026172,8.37890625 46.7960156,8.77242188 46.7960156,9.2578125 L46.7960156,17.109375 C46.7960156,17.5947656 46.4025,17.9882812 45.9171094,17.9882812 Z",
    fill: "#3D5868"
  }), _react.default.createElement("g", {
    transform: "translate(0.000000, 16.171875)",
    fill: "#D9D4D4"
  }, _react.default.createElement("path", {
    d: "M6.555,0.05859375 L3.4190625,0.05859375 C1.5703125,0.05859375 0.0662109375,1.56269531 0.0662109375,3.41144531 L0.0662109375,9.86988281 C0.0662109375,11.7186328 1.5703125,13.2227344 3.4190625,13.2227344 L6.555,13.2227344 C7.04039063,13.2227344 7.43390625,12.8292188 7.43390625,12.3438281 L7.43390625,0.9375 C7.43390625,0.452109375 7.04050781,0.05859375 6.555,0.05859375 Z"
  }), _react.default.createElement("path", {
    d: "M45.5655469,0.05859375 L42.4294922,0.05859375 C41.9439844,0.05859375 41.5505859,0.452109375 41.5505859,0.9375 L41.5505859,12.3437109 C41.5505859,12.8291016 41.9439844,13.2226172 42.4294922,13.2226172 L45.5655469,13.2226172 C47.4141797,13.2226172 48.9182813,11.7185156 48.9182813,9.86976562 L48.9182813,3.41132813 C48.9182812,1.56269531 47.4142969,0.05859375 45.5655469,0.05859375 Z"
  })), _react.default.createElement("path", {
    d: "M31.3447266,39.9796875 L17.6396484,39.9796875 C11.775,39.9796875 7.00371094,44.7509766 7.00371094,50.615625 L7.00371094,59.1210938 C7.00371094,59.6064844 7.39722656,60 7.88261719,60 L41.1016406,60 C41.5871484,60 41.9805469,59.6064844 41.9805469,59.1210938 L41.9805469,50.615625 C41.9805469,44.7509766 37.209375,39.9796875 31.3447266,39.9796875 Z",
    fill: "#F0EEEE"
  }), _react.default.createElement("path", {
    d: "M31.3447266,39.9796875 L27.9658594,39.9796875 C33.8305078,39.9796875 38.6017969,44.7509766 38.6017969,50.615625 L38.6017969,59.1210938 C38.6017969,59.6064844 38.2083984,60 37.7228906,60 L41.1017578,60 C41.5872656,60 41.9806641,59.6064844 41.9806641,59.1210938 L41.9806641,50.615625 C41.9805469,44.7509766 37.209375,39.9796875 31.3447266,39.9796875 Z",
    fill: "#D9D4D4"
  }), _react.default.createElement("g", {
    transform: "translate(3.632812, 0.000000)",
    fill: "#F0EEEE"
  }, _react.default.createElement("circle", {
    cx: "20.859375",
    cy: "20.7867187",
    r: "19.9078125"
  }), _react.default.createElement("path", {
    d: "M20.859375,0 C9.39738281,0 0.0723046875,9.32507813 0.0723046875,20.7871875 C0.0723046875,32.2492969 9.39738281,41.574375 20.859375,41.574375 C32.3213672,41.574375 41.6465625,32.2492969 41.6465625,20.7871875 C41.6465625,9.32507813 32.3214844,0 20.859375,0 Z"
  })), _react.default.createElement("path", {
    d: "M24.4921875,0 C23.8637109,0 23.2421484,0.0296484375 22.6277344,0.0844921875 C33.2194922,1.03054688 41.5504688,9.9534375 41.5504688,20.7870703 C41.5504688,31.6207031 33.219375,40.5437109 22.6277344,41.4897656 C23.2421484,41.5446094 23.8637109,41.5742578 24.4921875,41.5742578 C35.9542969,41.5742578 45.279375,32.2491797 45.279375,20.7870703 C45.279375,9.32507813 35.9542969,0 24.4921875,0 Z",
    fill: "#D9D4D4"
  }), _react.default.createElement("ellipse", {
    fill: "#FA8500",
    cx: "24.4921875",
    cy: "38.6753906",
    rx: "15.0339844",
    ry: "6.59765625"
  }), _react.default.createElement("path", {
    d: "M24.4921875,32.0776172 C24.2510156,32.0776172 24.01125,32.0803125 23.7728906,32.0852344 C31.0140234,32.25 36.7802344,35.1372656 36.7802344,38.6752734 C36.7802344,42.2132813 31.0140234,45.1005469 23.7728906,45.2653125 C24.0113672,45.2702344 24.2510156,45.2729297 24.4921875,45.2729297 C32.7951563,45.2729297 39.5260547,42.3189844 39.5260547,38.6751563 C39.5260547,35.0313281 32.7951563,32.0776172 24.4921875,32.0776172 Z",
    fill: "#CE6A06"
  }), _react.default.createElement("path", {
    d: "M44.7159375,15.9657422 C44.6844141,15.8333203 44.6226563,15.7100391 44.5354688,15.6055078 C42.1450781,12.7428516 39.1558594,10.5439453 35.6507813,9.07007813 C32.2920703,7.65773438 28.5376172,6.94160156 24.4921875,6.94160156 C20.4467578,6.94160156 16.6924219,7.65773438 13.3335938,9.07007813 C9.82851563,10.5439453 6.83929687,12.7427344 4.44890625,15.6055078 C4.36160156,15.7100391 4.29996094,15.8333203 4.2684375,15.9657422 C3.89460937,17.5395703 3.705,19.1616797 3.705,20.7871875 C3.705,32.2492969 13.0300781,41.574375 24.4921875,41.574375 C35.9542969,41.574375 45.279375,32.2492969 45.279375,20.7871875 C45.279375,19.1614453 45.0898828,17.5393359 44.7159375,15.9657422 Z",
    fill: "#5880A2"
  }), _react.default.createElement("path", {
    d: "M45.2710547,20.2089844 C45.2695313,20.1542578 45.2667188,20.0998828 45.2648437,20.0452734 C45.2599219,19.9061719 45.2542969,19.7670703 45.2465625,19.6280859 C45.2431641,19.5662109 45.2387109,19.5044531 45.2348437,19.4426953 C45.2262891,19.3103906 45.2169141,19.1780859 45.2058984,19.0460156 C45.200625,18.9832031 45.1948828,18.9203906 45.1891406,18.8576953 C45.1769531,18.7260938 45.1634766,18.5947266 45.1488281,18.4634766 C45.1419141,18.4016016 45.135,18.3397266 45.1276172,18.2780859 C45.1115625,18.1454297 45.0938672,18.0130078 45.0753516,17.8807031 C45.0669141,17.8209375 45.0591797,17.7611719 45.0502734,17.7015234 C45.0297656,17.5638281 45.0070312,17.4263672 44.9837109,17.2891406 C44.9745703,17.2358203 44.9666016,17.1821484 44.9571094,17.1288281 C44.9255859,16.9512891 44.8914844,16.7742188 44.8553906,16.5977344 C44.8337109,16.4919141 44.8098047,16.3870312 44.7864844,16.2817969 C44.7706641,16.2100781 44.7553125,16.1382422 44.7386719,16.0666406 C44.7275391,16.0189453 44.7152344,15.9718359 44.7038672,15.9242578 C44.67,15.8076562 44.61375,15.6987891 44.5357031,15.6052734 C42.9424219,13.6973437 41.0818359,12.0860156 38.9811328,10.7859375 C40.6177734,13.7548828 41.5507031,17.1636328 41.5507031,20.7869531 C41.5507031,31.6205859 33.2196094,40.5435937 22.6279688,41.4896484 C22.7779687,41.5030078 22.9284375,41.5148437 23.079375,41.5251562 C23.0912109,41.5259766 23.1030469,41.5266797 23.1147656,41.5273828 C23.2536328,41.5366406 23.3927344,41.5446094 23.5323047,41.5511719 C23.5808203,41.5533984 23.6294531,41.5548047 23.6780859,41.5566797 C23.7855469,41.5610156 23.8928906,41.5652344 24.0007031,41.5678125 C24.1641797,41.5716797 24.3280078,41.5740234 24.4924219,41.5740234 C35.9545313,41.5740234 45.2796094,32.2489453 45.2796094,20.7868359 C45.279375,20.5942969 45.2762109,20.4016406 45.2710547,20.2089844 Z",
    fill: "#3F6582"
  }), _react.default.createElement("circle", {
    fill: "#FB403F",
    cx: "15.4981641",
    cy: "51.1324219",
    r: "2.38277344"
  }), _react.default.createElement("path", {
    d: "M33.7358203,48.5742188 L28.7251172,48.5742188 C27.4228125,48.5742188 26.3671875,49.6299609 26.3671875,50.9321484 L26.3671875,54.1458984 C26.3671875,55.4482031 27.4228125,56.5038281 28.7251172,56.5038281 L33.7358203,56.5038281 C35.038125,56.5038281 36.09375,55.4480859 36.09375,54.1458984 L36.09375,50.9321484 C36.09375,49.6299609 35.038125,48.5742188 33.7358203,48.5742188 Z",
    fill: "#5880A2"
  }), _react.default.createElement("g", {
    transform: "translate(28.125000, 50.156250)",
    fill: "#F0EEEE"
  }, _react.default.createElement("path", {
    d: "M5.00003906,1.85542969 L0.976523437,1.85542969 C0.491015625,1.85542969 0.0976171875,1.46191406 0.0976171875,0.976523438 C0.0976171875,0.491132813 0.491015625,0.0976171875 0.976523437,0.0976171875 L5.00003906,0.0976171875 C5.48554687,0.0976171875 5.87894531,0.491132813 5.87894531,0.976523438 C5.87894531,1.46191406 5.48554688,1.85542969 5.00003906,1.85542969 Z"
  }), _react.default.createElement("path", {
    d: "M5.00003906,4.66792969 L0.976523437,4.66792969 C0.491015625,4.66792969 0.0976171875,4.27441406 0.0976171875,3.78902344 C0.0976171875,3.30363281 0.491015625,2.91011719 0.976523437,2.91011719 L5.00003906,2.91011719 C5.48554687,2.91011719 5.87894531,3.30363281 5.87894531,3.78902344 C5.87894531,4.27441406 5.48554688,4.66792969 5.00003906,4.66792969 Z"
  })))))));
};

var _default = Astronaut;
exports.default = _default;