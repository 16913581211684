"use strict";

var _emotion = require("emotion");

var _webfontloader = _interopRequireDefault(require("webfontloader"));

var _settings = require("../settings");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

_webfontloader.default.load({
  google: {
    families: ['Work+Sans:400,700', 'Righteous', 'sans-serif']
  }
});

(0, _emotion.injectGlobal)("*{box-sizing:border-box;}html{font-size:", _settings.baseFontSize, "px}body{margin:0;}");