"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.applyVariants = exports.psuedo = exports.base = void 0;

var _react = _interopRequireDefault(require("react"));

var _emotion = _interopRequireDefault(require("@rebass/components/emotion"));

var _settings = require("../../settings");

var _utils = require("../../utils");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var base = {
  cursor: 'pointer',
  border: 'none',
  position: 'relative',
  outline: 'none'
};
exports.base = base;
var psuedo = {
  ':before,:after': {
    content: '" "',
    position: 'absolute',
    zIndex: -2,
    transition: 'all 250ms ease-out'
  }
};
exports.psuedo = psuedo;

var main = _objectSpread({
  fontFamily: _settings.fonts.h,
  color: '#fff',
  transition: '.25s',
  fontSize: '1em',
  height: (0, _utils.pxToEm)(42),
  width: '100%',
  borderRadius: (0, _utils.pxToEm)(25),
  letterSpacing: 0.2
}, psuedo);

var addDepth = function addDepth(hex) {
  var shadow = (0, _utils.opacity)(hex, 0.3);
  var inset = (0, _utils.opacity)(_settings.colors.midnight, 0.1);
  return {
    boxShadow: "0 2px 4px ".concat(shadow, ", 0 0 40px ").concat(inset, " inset"),
    ':before': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: (0, _utils.pxToEm)(25)
    },
    ':hover': {
      boxShadow: "0 3px 4px ".concat(shadow, ", 0 0 40px ").concat(inset, " inset"),
      transform: 'translateY(-1px)',
      ':before': {
        boxShadow: "0 15px 10px -10px ".concat(shadow)
      }
    },
    ':focus': {
      boxShadow: "0 0px 4px ".concat(shadow, ", 0 0 40px ").concat(inset, " inset")
    }
  };
};

var variants = {
  primary: _objectSpread({
    backgroundColor: _settings.colors.purple
  }, addDepth(_settings.colors.purple)),
  primary2: _objectSpread({
    backgroundColor: _settings.colors.blue
  }, addDepth(_settings.colors.blue)),
  second: _objectSpread({
    backgroundColor: 'white',
    color: (0, _utils.opacity)(_settings.colors.dusk, 0.84)
  }, addDepth(_settings.colors.dusk)),
  warn: _objectSpread({
    backgroundColor: _settings.colors.red
  }, addDepth(_settings.colors.red))
};

var applyVariants = function applyVariants(props) {
  return _objectSpread({}, variants[props.variant]);
};

exports.applyVariants = applyVariants;

var _Button = (0, _emotion.default)({
  extend: (0, _emotion.default)({
    is: 'button',
    blacklist: ['variant']
  })
}, _objectSpread({}, base, main), applyVariants);

var Button = function Button(props) {
  return _react.default.createElement(_Button, _extends({}, props, {
    variant: "primary"
  }));
};

Button.two = function (props) {
  return _react.default.createElement(_Button, _extends({}, props, {
    variant: "primary2"
  }));
};

Button.second = function (props) {
  return _react.default.createElement(_Button, _extends({}, props, {
    variant: "second"
  }));
};

Button.warn = function (props) {
  return _react.default.createElement(_Button, _extends({}, props, {
    variant: "warn"
  }));
};

var _default = Button;
exports.default = _default;