import firebase from 'firebase/app'
import 'firebase/firestore'
import configureFirestoreMiddleware from 'redux-firestore-middleware'
import { configureApiMiddleware } from 'redux-axios-api-middleware'

/*****************************************************************
 * FIREBASE
 */
let fbConfig = {
  apiKey: 'AIzaSyC0PrG631OTi-N9L63T_VEwcxRBjqnUsEQ',
  authDomain: 'order.boostly.com',
  projectId: 'boostly-live'
}

if (process.env.__PROD__ === 'false') {
  fbConfig = {
    apiKey: 'AIzaSyA2tuaFSWaaura4F7L7KPPPueP4W9PxBrc',
    authDomain: 'order-staging.boostly.com',
    projectId: 'boostly-84933'
  }
}
console.log(fbConfig)
firebase.initializeApp(fbConfig)

const CALL_STORE = 'CALL_STORE'
export const callStore = config => ({ [CALL_STORE]: { ...config } })

const firestore = firebase.firestore()
firestore.settings({ timestampsInSnapshots: true })

export const db = firestore
export const firestoreMiddleware = configureFirestoreMiddleware({
  firestoreInstance: firestore,
  MIDDLEWARE_FLAG: CALL_STORE
})

/*****************************************************************
 * API
 */
let apiRoot = 'https://boostly-live.herokuapp.com/'

if (process.env.__STAGE__ === 'true') {
  apiRoot = 'https://boostly-stage.herokuapp.com/'
}

if (process.env.__LOCAL__ === 'true') {
  apiRoot = 'http://localhost:9000/'
}

export const API_ROOT = apiRoot

const CALL_API = 'CALL_API'
export const callApi = config => ({ [CALL_API]: { ...config } })

export const apiMiddleware = configureApiMiddleware(CALL_API, API_ROOT)
