"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.baseFontSize = exports.fonts = exports.colors = void 0;

var colors = require('./colors').default;

exports.colors = colors;
var fonts = {
  h: 'Righteous',
  text: 'Work Sans'
};
exports.fonts = fonts;
var baseFontSize = 16;
exports.baseFontSize = baseFontSize;