import React, { Component, Fragment, useState } from 'react'
import { css, keyframes } from 'react-emotion'
import { TransitionMotion, spring } from 'react-motion'
import {
  Absolute,
  ArrowIcon,
  Avatar,
  Box,
  Button,
  Circle,
  Checkbox,
  CloseIcon,
  Col,
  Modal,
  PaperAirplaneIcon,
  Text,
  Row,
  settings as s
} from 'App/UI'
import { ViewportConsumer } from 'App'
import ContentEditable from 'App/shared/ContentEditable'
import { TinderTutorial } from '../Tutorials'
import { InfoIcon } from 'App/shared/Icons'
import ClassificationBadge from '../ClassificationBadge'
import { customerConfig } from '../../config'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const rise = keyframes`
  0% {
    transform: translateY(100%);
  }
`

const CustomerCard = React.forwardRef(
  ({ info, primeClassification, classifications, badgeConfig }, ref) => (
    <Box
      innerRef={ref}
      bg="white"
      borderRadius="8"
      w="100%"
      maxWidth="300px"
      position="relative"
    >
      {badgeConfig && (
        <Absolute
          top="10px"
          {...(badgeConfig.position === 'right'
            ? { right: '10px' }
            : { left: '10px' })}
        >
          {badgeConfig.icon}
        </Absolute>
      )}
      <Col textAlign="center" x p={2}>
        <Box mt={-4}>
          <Avatar.default bg={customerConfig[primeClassification.type].color} />
        </Box>
        <Text.title size={3} my={1}>
          {info.firstName} {info.lastName}
        </Text.title>
        {['phoneNumber', 'email'].map((prop, i) => (
          <Text key={i} mb={1}>
            {info[prop]}
          </Text>
        ))}
        <Row my={1}>
          {classifications.map((c, i) => {
            if (!customerConfig[c.type]) {
              return
            }
            const { color, Icon, statName } = customerConfig[c.type]
            return (
              <ClassificationBadge
                key={i}
                color={color}
                Icon={Icon}
                stat={c.stats[statName]}
              />
            )
          })}
        </Row>
      </Col>
    </Box>
  )
)

const ConfigurationInstructionsModal = ({ isOpen, onRequestClose }) => {
  return (
    <Modal isOpen={isOpen} popup onRequestClose={onRequestClose}>
      {({ Close, Container, Jewel, ContentContainer }) => (
        <Container>
          <Close />
          <ContentContainer>
            <Jewel bg={s.colors.yellow}>
              <Text size={6} mb="-7px">
                ⚙️
              </Text>
            </Jewel>
            <Box p={2} textAlign="center">
              <p>
                <Text.title>Hey!</Text.title>
              </p>
              <p>
                It looks like you haven't been set up to send texts to your
                customers. Give us a call and we can discuss getting you set up
                as a beta user.
              </p>
              <p>
                <Text.title>Boostly Customer Support:</Text.title>
              </p>
              <p>
                <a href="tel:+18007207738">800-720-7738</a>
              </p>
            </Box>
          </ContentContainer>
        </Container>
      )}
    </Modal>
  )
}

const EditTxtMessageModal = props => {
  let txt = null
  let input
  const [state, updateState] = useState({
    includePromo: props.includePromo
  })
  const setState = newState =>
    updateState({
      ...state,
      newState
    })
  const onChange = e => {
    txt = e.target.value
  }
  const onIncludePromoChange = includePromo =>
    setState({
      includePromo
    })

  const close = () => {
    props.onRequestClose()
  }

  const onSave = () => {
    props.onSave({
      txt: txt || props.txt,
      includePromo: state.includePromo
    })
  }

  const { isOpen, customer } = props
  return (
    <Modal isOpen={isOpen} popup onRequestClose={close}>
      {({ Close, Container }) => (
        <Container>
          <Close />
          <Box
            borderRadius="20px 20px 0 0"
            bg="white"
            maxWidth="300px"
            m="auto"
            css={`
              overflow: hidden;
            `}
          >
            <Row h="50px" px={1} y bg={s.colors.purple} space="between">
              <Row y>
                <Avatar.default size="small" bg={customer.color} />
                <Text.title color="white" ml={1}>
                  {customer.info.firstName} {customer.info.lastName}
                </Text.title>
              </Row>
              <Box>
                <ClassificationBadge
                  color={customer.color}
                  Icon={customer.badgeIcon}
                  stat={customer.badgeStat}
                />
              </Box>
            </Row>
            <Box
              p={3}
              minHeight="144px"
              maxHeight="300px"
              css={css`
                overflow: auto;
              `}
            >
              <ContentEditable
                className={css`
                  outline: none;
                  text-align: left;
                  white-space: pre-wrap;
                  word-wrap: break-word;
                `}
                content={props.txt}
                onChange={onChange}
                getRef={node => (input = node)}
              />
            </Box>
            <Row
              h="70px"
              y
              space="around"
              css={`
                box-shadow: 0 -1px 1px 1px rgba(0, 0, 0, 0.2);
              `}
            >
              <Row y>
                <Checkbox
                  checked={state.includePromo}
                  onChange={onIncludePromoChange}
                />
                <Text.title size=".8em" ml={1}>
                  Include Promo
                </Text.title>
              </Row>
              <Box w="90px">
                <Button.two onClick={onSave}>Save 👌</Button.two>
              </Box>
            </Row>
          </Box>
        </Container>
      )}
    </Modal>
  )
}

const actions = {
  sentText: 'SENT_TEXT',
  none: 'NONE'
}

const TxtBox = ({ isSent, content, onClick = _ => _, maxHeight }) => (
  <Box
    {...{
      onClick,
      bg: isSent ? s.colors.blue : '#03C840',
      borderRadius: '8px 8px 0 8px',
      p: 3,
      w: '100%',
      css: css`
        cursor: auto;
        overflow: auto;
      `,
      maxHeight
    }}
  >
    <Text.p color="white">{content}</Text.p>
  </Box>
)

const CardSwipe = props => {
  const willLeave = () => ({
    x: spring(window.innerWidth / 1.5 + 200, { precision: 1 }),
    left: spring(window.innerWidth + 100),
    rotate: spring(45, { precision: 1 })
  })

  const negate = val => '-' + val
  const { getDirection, id, shouldRotate, position } = props
  return (
    <TransitionMotion
      willLeave={willLeave}
      styles={[id].map((key, i) => {
        const offset = window.innerWidth / 2
        return {
          key,
          data: {
            content: props.children,
            shouldRotate,
            getDirection,
            position: position || 'relative'
          },
          style: {
            x: 0,
            left: offset,
            rotate: 0,
            opacity: 1
          }
        }
      })}
    >
      {interpolatedStyles => (
        // first render: a, b, c. Second: still a, b, c! Only last one's a, b.
        <div>
          {interpolatedStyles.map(({ style, data, key }) => {
            const isLeft = data.getDirection() === 'left'
            const transform = isLeft ? negate : _ => _

            return (
              <Box
                key={key}
                css={css`
                  position: ${data.position};
                  animation: ${fadeIn} 1.2s ease;
                  transform: translateX(${transform(style.x)}px)
                    rotate(
                      ${transform(data.shouldRotate ? style.rotate : 0)}deg
                    );
                `}
              >
                {data.content}
              </Box>
            )
          })}
        </div>
      )}
    </TransitionMotion>
  )
}

const createDefaultTxt = ({
  customer = { firstName: '' },
  interactionConfig: { pointOfContact, locationName, period }
}) =>
  `Hey ${customer.firstName.trim()}, this is ${pointOfContact.firstName} the ${
    pointOfContact.role
  } of ${locationName}. Just wanted to thank you for choosing to eat with us ${period}.`

const createUnconfiguredDefaultTxt = ({
  customer = { firstName: '' },
  interactionConfig: { locationName, period }
}) =>
  `Hey ${customer.firstName.trim()}, this is the manager of ${locationName}. Just wanted to thank you for choosing to eat with us ${period}.`

const promoLine = ({ promoLink, promoDescription }) =>
  promoLink
    ? `Use this link to enjoy ${promoDescription} on me: ${promoLink}.`
    : ''

const CONTAINER_WIDTH = 300
const ANIMATION_TIME = 0.5
const DEFAULT_TXTBOX_HEIGHT = 200
const makeActionConfig = actionTaken =>
  ({
    SENT_TEXT: {
      icon: (
        <Circle
          bg={s.colors.blue}
          css={css`
            animation: ${fadeIn} ${ANIMATION_TIME}s ease;
          `}
        >
          <PaperAirplaneIcon center color={'white'} />
        </Circle>
      ),
      position: 'right'
    },
    NONE: {
      icon: (
        <Circle bg={s.colors.red}>
          <CloseIcon color={'white'} />
        </Circle>
      ),
      position: 'left'
    }
  }[actionTaken])

const CustomerTinder = props => {
  const startIndex = props.startIndex || 0
  const isConfigured = !!props.interactionConfig.pointOfContact
  const [state, updateState] = useState({
    index: startIndex,
    isEditingTxt: false,
    showTinderTutorial: false,
    showConfigurationInstructions: false,
    txtMessage: isConfigured
      ? createDefaultTxt({
        customer: (props.customers[startIndex] || {}).info,
        interactionConfig: props.interactionConfig
      })
      : createUnconfiguredDefaultTxt({
        customer: (props.customers[startIndex] || {}).info,
        interactionConfig: props.interactionConfig
      }),
    includePromo: true,
    swipeDirection: '',
    swipeCount: 0,
    txtBoxHeight: '200px',
    isConfigured,
    isUndone: false
  })

  const setState = newState =>
    updateState({
      ...state,
      ...newState
    })

  const onTxtSave = ({ txt, includePromo }) =>
    setState({
      txtMessage: txt,
      includePromo,
      isEditingTxt: false
    })

  const createUnsentTxtMessage = () =>
    `${state.txtMessage} ${
      state.includePromo ? promoLine(props.interactionConfig) : ''
    }`

  const getCurrentInteraction = () => {
    const { customers, interactions } = props
    const currentCustomer = customers[state.index]

    return interactions[currentCustomer.info.id] || {}
  }

  const onUndo = () =>
    setState({
      isUndone: true
    })

  const getLastIndex = () => props.customers.length - 1
  const openTxtEditor = () => setState({ isEditingTxt: true })
  const closeTxtEditor = () => setState({ isEditingTxt: false })
  const openConfigurationInstructions = () =>
    setState({ showConfigurationInstructions: true })
  const closeConfigurationInstructions = () =>
    setState({ showConfigurationInstructions: false })
  const showTinderTutorial = () => setState({ showTinderTutorial: true })
  const closeTinderTutorial = () => setState({ showTinderTutorial: false })

  const getNextIndex = () =>
    state.index === getLastIndex() ? 0 : state.index + 1
  const getPrevIndex = () =>
    state.index === 0 ? getLastIndex() : state.index - 1
  const getSwipeDirection = () => state.swipeDirection

  const prev = ({ swipeDirection = 'right' }) => {
    const index = getPrevIndex()
    setState({
      index,
      isUndone: false,
      swipeDirection,
      txtMessage: createDefaultTxt({
        customer: props.customers[index].info,
        interactionConfig: props.interactionConfig
      })
    })
  }

  const next = ({ swipeDirection = 'left' }) => {
    const index = getNextIndex()
    setState({
      index,
      isUndone: false,
      swipeDirection,
      txtMessage: createDefaultTxt({
        customer: props.customers[index].info,
        interactionConfig: props.interactionConfig
      })
    })
  }
  /**
   * 1. if there is no interaction to update this will be undefined.
   */
  const onActionApprove = () => {
    next({ swipeDirection: 'right' })
    props
      .onApproveAction({
        textMessage: createUnsentTxtMessage(),
        includedPromo: state.includePromo,
        customer: props.customers[state.index].info,
        /* [1] */
        interactionToUpdate: state.isUndone && getCurrentInteraction().id
      })
      .then(successful => {
        if (successful) {
          // notify success
        }
      })
  }

  const onActionDecline = () => {
    next({ swipeDirection: 'left' })
    props
      .onDeclineAction({
        customer: props.customers[state.index].info
      })
      .then(successful => {
        if (successful) {
          // notify problem
        }
      })
  }

  let cardRef = null
  let controlPanelRef = null
  const determineSpaceBetweenCardAndControlPanel = (cardEl, controlPanelEl) => {
    const cardBottom = cardEl.getBoundingClientRect().bottom
    const controllPanelTop = controlPanelEl.getBoundingClientRect().top
    const maxHeight = controllPanelTop - cardBottom

    return maxHeight
  }
  /**
   * @desc screen realestate on mobile can be precious sometimes. In these cases
   * we want to decrease the height of the textbox so it doesn't cover the user
   * information. We do this by calculating how much space is betwen the bottom
   * of the user card and the top of the control panel. if that space is less
   * than the default textbox height, we adjust.
   * 1. If the refs haven't been set yet, then we can't do calculations
   * 2. the "padding value", aka space leftover between card and textbox
   * 3. use default height if it's less than the available space
   * 4. only adjust height if it's changed
   */
  const maybeAdjustTextBoxHeight = () => {
    if (!cardRef || !controlPanelRef) return /* [1] */
    const maxHeight = determineSpaceBetweenCardAndControlPanel(
      cardRef,
      controlPanelRef
    )
    const txtBoxHeight =
      maxHeight < DEFAULT_TXTBOX_HEIGHT
        ? maxHeight - 8 /* [2] */ + 'px'
        : DEFAULT_TXTBOX_HEIGHT /* [3] */

    if (txtBoxHeight !== state.txtBoxHeight) {
      /* [4] */
      setState({
        txtBoxHeight
      })
    }
  }

  const setControlPanelRef = el => {
    if (!el) return
    controlPanelRef = el
    maybeAdjustTextBoxHeight()
  }
  const setCardRef = el => {
    if (!el) return
    cardRef = el
    maybeAdjustTextBoxHeight()
  }

  const containerWidth = `${CONTAINER_WIDTH}px`
  const currentCustomer = props.customers[state.index]

  if (!currentCustomer) {
    return <div>Missing Customers</div>
  }

  const {
    color: customerColor,
    Icon: customerIcon,
    statName: customerStatName
  } = customerConfig[currentCustomer.primeClassification.type]

  const interaction = props.interactions[currentCustomer.info.id] || {}
  const actionConfig = makeActionConfig(interaction.actionTaken)
  const txtWasSent = interaction.actionTaken === actions.sentText

  const actionWasDeclined =
    !state.isUndone && interaction.actionTaken === actions.none

  /**
are they configured?
if yes.. operate as normal
if no.. do popup
[ ] How to determine isConfigured
[ ] what will pop say?
[ ] wire up the conditional
[ ] profit
*/
  return (
    <Box>
      <ConfigurationInstructionsModal
        isOpen={state.showConfigurationInstructions}
        onRequestClose={closeConfigurationInstructions}
      />
      <EditTxtMessageModal
        customer={{
          info: currentCustomer.info,
          color: customerColor,
          badgeIcon: customerIcon,
          badgeStat: currentCustomer.primeClassification.stats[customerStatName]
        }}
        txt={state.txtMessage}
        includePromo={state.includePromo}
        isOpen={state.isEditingTxt}
        onRequestClose={closeTxtEditor}
        onSave={onTxtSave}
      />
      <TinderTutorial
        isOpen={state.showTinderTutorial}
        onRequestClose={closeTinderTutorial}
      />
      <Box w={containerWidth}>
        <CardSwipe
          id={currentCustomer.info.id}
          shouldRotate={!interaction.actionTaken}
          getDirection={getSwipeDirection}
          position="absolute"
        >
          <Box w={containerWidth}>
            <CustomerCard
              ref={setCardRef}
              {...currentCustomer}
              badgeConfig={
                actionConfig && {
                  icon: actionConfig.icon,
                  position: actionConfig.position
                }
              }
            />
          </Box>
          {txtWasSent && (
            <Row
              x
              mt={3}
              w={containerWidth}
              mx="auto"
              css={css`
                animation: ${fadeIn} ${ANIMATION_TIME}s ease,
                  ${rise} ${ANIMATION_TIME}s ease;
              `}
            >
              <TxtBox
                isSent={txtWasSent}
                content={interaction.textMessage}
                maxHeight={state.txtBoxHeight}
              />
            </Row>
          )}
          {actionWasDeclined && (
            <Col x mt={3} w={containerWidth} mx="auto">
              <Text.title color="white">
                Action was declined for {currentCustomer.info.firstName}
              </Text.title>
              <Text color="white" mt={2} mb={3}>
                Changed your mind?
              </Text>
              <Box w="200px">
                <Button.warn onClick={() => onUndo(state.index)}>
                  Undo
                </Button.warn>
              </Box>
            </Col>
          )}
        </CardSwipe>
      </Box>
      <ViewportConsumer>
        {({ viewBottom, innerHeight }) => (
          <Box
            position="absolute"
            bottom={
              viewBottom - innerHeight > 0 ? -(viewBottom - innerHeight) : 0
            }
            left="0"
            w="100%"
          >
            {!txtWasSent && !actionWasDeclined && (
              <Row x w={containerWidth} mx="auto">
                <CardSwipe
                  id={currentCustomer.info.id}
                  shouldRotate={!interaction.actionTaken}
                  getDirection={getSwipeDirection}
                >
                  <TxtBox
                    isSent={txtWasSent}
                    content={createUnsentTxtMessage()}
                    onClick={
                      isConfigured
                        ? openTxtEditor
                        : openConfigurationInstructions
                    }
                    maxHeight={state.txtBoxHeight}
                  />
                </CardSwipe>
              </Row>
            )}

            <ControlPanel
              {...{
                containerWidth,
                actionTaken: interaction.actionTaken,
                currentCardIndex: state.index,
                cardCount: props.customers.length,
                isUndone: state.isUndone,
                next: state.isConfigured ? next : openConfigurationInstructions,
                prev: state.isConfigured ? prev : openConfigurationInstructions,
                onActionDecline: state.isConfigured
                  ? onActionDecline
                  : openConfigurationInstructions,
                onActionApprove: state.isConfigured
                  ? onActionApprove
                  : openConfigurationInstructions,
                onInfoSelect: showTinderTutorial,
                setControlPanelRef
              }}
            />
          </Box>
        )}
      </ViewportConsumer>
    </Box>
  )
}

export default CustomerTinder

const ControlPanel = ({
  containerWidth,
  cardCount,
  currentCardIndex,
  next,
  prev,
  onInfoSelect,
  onActionDecline,
  onActionApprove,
  actionTaken,
  isUndone,
  setControlPanelRef
}) => {
  const renderCtas = actionWasTaken =>
    actionWasTaken
      ? {
        left: (
          <Circle.Button onClick={prev}>
            <ArrowIcon size={20} color="rgba(255,255,255,0.84)" />
          </Circle.Button>
        ),
        right: (
          <Circle.Button onClick={next}>
            <ArrowIcon
              size={20}
              direction="right"
              color="rgba(255,255,255,0.84)"
            />
          </Circle.Button>
        )
      }
      : {
        left: (
          <Circle.Button.warn onClick={onActionDecline}>
            <CloseIcon color="white" />
          </Circle.Button.warn>
        ),
        right: (
          <Circle.Button.two onClick={onActionApprove}>
            <PaperAirplaneIcon center color="white" />
          </Circle.Button.two>
        )
      }
  const ctas =
    cardCount > 1
      ? renderCtas(isUndone ? false : actionTaken)
      : { left: <div />, right: <div /> }
  return (
    <Box py={3} innerRef={setControlPanelRef}>
      <Row x y mx="auto" w={containerWidth} space="between">
        {ctas.left}
        <Box
          px={4}
          css={`
            user-select: none;
          `}
        >
          <Text.title size="4" color="white">
            {currentCardIndex + 1} / {cardCount}
          </Text.title>
        </Box>
        {ctas.right}
      </Row>
      <Row x>
        <Box onClick={onInfoSelect}>
          <InfoIcon />
        </Box>
      </Row>
    </Box>
  )
}
