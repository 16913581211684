"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _exportNames = {
  settings: true,
  utils: true
};
exports.utils = exports.settings = void 0;

require("./global");

var _settings = _interopRequireWildcard(require("./settings"));

exports.settings = _settings;

var _utils = _interopRequireWildcard(require("./utils"));

exports.utils = _utils;

var _components = require("./components");

Object.keys(_components).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (Object.prototype.hasOwnProperty.call(_exportNames, key)) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function get() {
      return _components[key];
    }
  });
});

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {}; if (desc.get || desc.set) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } } newObj.default = obj; return newObj; } }