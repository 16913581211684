import './style'
import React, { Component, createContext } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { connect } from 'react-router-dom'
import { Box } from 'App/UI'
import useWindowSize from 'App/hooks/useWindowSize'

const AppContext = createContext({})
export const ViewportConsumer = AppContext.Consumer
const ViewportProvider = ({ children }) => children({ ...useWindowSize() })
class App extends Component {
  state = {
    viewBottom: ''
  }
  setRef = el => {
    if (!el) return
    const viewBottom = el.getBoundingClientRect().bottom
    if (this.state.viewBottom !== viewBottom) {
      this.setState({
        viewBottom
      })
    }
  }
  render() {
    return (
      <div
        style={{ width: '100%', height: '100%' }}
        ref={el => this.setRef(el)}
      >
        <ViewportProvider>
          {vpState => (
            <AppContext.Provider value={{ ...this.state, ...vpState }}>
              <Route
                path="/analysis/customers/:orgId/:locId"
                component={require('./views').ConsumerAnalysis}
              />
              <Route
                path="/analysis/summary/:orgId/:locId"
                component={require('./views').SummaryAnalysis}
              />
            </AppContext.Provider>
          )}
        </ViewportProvider>
      </div>
    )
  }
}

export default withRouter(App)
