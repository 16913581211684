"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Badge = void 0;

var _system = _interopRequireDefault(require("../../utils/system"));

var _settings = require("../../settings");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Badge = (0, _system.default)({
  fontSize: 0,
  fontFamily: _settings.fonts.h,
  px: 2,
  py: 1,
  mx: 1,
  color: 'white',
  bg: _settings.colors.purple,
  fontWeight: 'bold',
  borderRadius: 25
}, {
  WebkitFontSmoothing: 'antialiased',
  display: 'inline-block',
  verticalAlign: 'middle'
}, 'space', 'color', 'borderRadius', 'fontSize', 'fontFamily', 'fontWeight');
exports.Badge = Badge;
Badge.displayName = 'Badge';
var _default = Badge;
exports.default = _default;