import React, { Component, Fragment } from 'react'
import {
  Box,
  Button,
  Circle,
  CloseIcon,
  Modal,
  PaperAirplaneIcon,
  Text,
  Row,
  settings as s
} from 'App/UI'
import { BlockIcon, HamburgerIcon } from 'App/shared/Icons'

export const CustomerTutorial = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    {({ Close, Jewel, Container, ContentContainer }) => (
      <Container>
        <Close />
        <ContentContainer>
          <Jewel bg={s.colors.blue}>
            <Text size={6} mb="-7px">
              ✨
            </Text>
          </Jewel>
          <Box textAlign="center" mt={2}>
            <Text.title>Let's Delight Your Customers</Text.title>
            <Text.p pt={1} size="14px">
              We’ve tailor picked your most valuable customers for you to
              consider sending some dazzle their way.
            </Text.p>
            <Box textAlign="left">
              <Row y pt={3} pb={1}>
                <HamburgerIcon color={s.colors.purple} size={20} />
                <Text.title ml={3}>List View</Text.title>
              </Row>
              <Text.p size="14px">
                Get an overview of the types of customers you had and what
                actions have or have not been taken.
              </Text.p>

              <Row y pt={3} pb={1}>
                <BlockIcon color={s.colors.purple} size={20} />
                <Text.title ml={3}>Card View</Text.title>
              </Row>
              <Text.p size="14px">
                This is were the action takes place. View your customers in
                greater detail and determine what action to take with them.
              </Text.p>
            </Box>
            <Box pt={3} pb={1} m="0 auto" w="150px">
              <Button.two onClick={onRequestClose}>Got it 👌</Button.two>
            </Box>
          </Box>
        </ContentContainer>
      </Container>
    )}
  </Modal>
)

export const TinderTutorial = ({ isOpen, onRequestClose }) => (
  <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
    {({ Close, Jewel, Container, ContentContainer }) => (
      <Container>
        <Close />
        <ContentContainer>
          <Jewel bg={s.colors.blue}>
            <Text size={6} mb="-7px">
              👋
            </Text>
          </Jewel>
          <Box textAlign="center" mt={2}>
            <Text.p size="14px">
              This view is designed to help make delighting your customers quick
              and simple. Here’s how:
            </Text.p>
            <Row y pt={3} pb={1}>
              <Box pr={2}>
                <Circle bg={s.colors.blue}>
                  <PaperAirplaneIcon center color="white" />
                </Circle>
              </Box>
              <Box textAlign="left">
                <Text.title>Send Text</Text.title>
                <Text.p size="14px">
                  Hit the airplane to send an SMS text to the customer
                </Text.p>
              </Box>
            </Row>
            <Row y pt={3} pb={1}>
              <Box pr={2}>
                <Circle bg={s.colors.red}>
                  <CloseIcon color="white" />
                </Circle>
              </Box>
              <Box textAlign="left">
                <Text.title>Decline Action</Text.title>
                <Text.p size="14px">
                  We won’t prompt you to take action with this customer again
                </Text.p>
              </Box>
            </Row>
            <Row y pt={3} pb={1}>
              <Box pr={3} pl={1}>
                <Box
                  borderRadius={'25px 25px 0 25px'}
                  w="45px"
                  h="45px"
                  bg="#03C840"
                />
              </Box>
              <Box textAlign="left">
                <Text.title>Edit Message</Text.title>
                <Text.p size="14px">
                  Hit the message box to make changes before you hit send
                </Text.p>
              </Box>
            </Row>

            <Box pt={3} pb={1} m="0 auto" w="150px">
              <Button.two onClick={onRequestClose}>Got it 👌</Button.two>
            </Box>
          </Box>
        </ContentContainer>
      </Container>
    )}
  </Modal>
)
