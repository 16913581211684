import React, { Component, Fragment } from 'react'
import { css } from 'react-emotion'
import {
  Avatar,
  CloseIcon,
  PaperAirplaneIcon,
  Text,
  Box,
  Row,
  settings as s
} from 'App/UI'
import { customerConfig } from '../../config'
import ClassificationBadge from '../ClassificationBadge'

const makeActionConfig = actionTaken =>
  ({
    SENT_TEXT: {
      icon: <PaperAirplaneIcon center color={s.colors.blue} />
    },
    NONE: {
      icon: <CloseIcon color={s.colors.red} />
    }
  }[actionTaken])

const CustomerList = ({ customers, interactions, goToCustomer }) => {
  return (
    <Box>
      <Row
        bg={s.colors.purple}
        py={2}
        mb={3}
        borderRadius="9999px 0 0 9999px"
        space="between"
      >
        <Box ml={5}>
          <Text.title color="white">Name</Text.title>
        </Box>
        <Row>
          <Box w="75px">
            <Text.title color="white">Type</Text.title>
          </Box>
          <Box w="60px">
            <Text.title color="white">Status</Text.title>
          </Box>
        </Row>
      </Row>
      <Box
        css={css`
          height: calc(100% - 175px);
          overflow-y: scroll;
          -webkit-overflow-scrolling: touch;
        `}
      >
        {customers.map((c, i) => {
          const { color, Icon, statName } = customerConfig[
            c.primeClassification.type
          ]
          const interaction = interactions[c.info.id]
            ? interactions[c.info.id].meta
            : {}
          const actionConfig = makeActionConfig(interaction.actionTaken) || {}
          return (
            <Row
              y
              key={i}
              mt={i > 0 ? 3 : 0}
              bg="white"
              px={1}
              py={2}
              space="between"
              borderRadius="9999px 0 0 9999px"
              onClick={() => goToCustomer(i)}
            >
              <Row y>
                <Avatar.default size="medium" bg={color} />
                <Text.title ml={2}>
                  {c.info.firstName} {c.info.lastName}
                </Text.title>
              </Row>
              <Row y>
                <ClassificationBadge
                  color={color}
                  Icon={Icon}
                  stat={c.primeClassification.stats[statName]}
                />
                <Row w="50px" x>
                  {actionConfig.icon}
                </Row>
              </Row>
            </Row>
          )
        })}
      </Box>
    </Box>
  )
}

export default CustomerList
