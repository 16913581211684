"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _react = _interopRequireDefault(require("react"));

var _emotion = require("emotion");

var _Icons = require("../Icons");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Close = function Close(_ref) {
  var onClick = _ref.onClick,
      size = _ref.size,
      _ref$color = _ref.color,
      color = _ref$color === void 0 ? 'rgba(255, 255, 255, 0.84)' : _ref$color;
  return _react.default.createElement("div", {
    onClick: onClick
  }, _react.default.createElement(_Icons.CloseIcon, {
    style: ':hover {cursor: pointer;transform: scale(1.1);}',
    color: color
  }));
};

var _default = Close;
exports.default = _default;