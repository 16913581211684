import axios from 'axios'
import { combineReducers } from 'redux'
import queryString from 'query-string'
import { createReducer } from 'state/utils'
import { API_ROOT } from 'config/api'
export const callStore = require('config/api').callStore
export const callApi = require('config/api').callApi
export const api = config =>
  new Promise(resolve => {
    axios({
      ...config,
      url: `${API_ROOT}${config.endpoint}`
    }).then(response => resolve(response.data))
  })

const entityNames = ['locations', 'interactions']

const initialEntityState = entityNames.reduce(
  (state, entity) => ({ ...state, [entity]: {} }),
  {}
)

const createReducerFn = name => (
  state = initialEntityState[name],
  { payload }
) =>
  payload && payload.entities && payload.entities[name]
    ? { ...state, ...payload.entities[name] }
    : state

const entities = combineReducers(
  entityNames.reduce(
    (state, entity) => ({ ...state, [entity]: createReducerFn(entity) }),
    {}
  )
)

export const selectQuery = (state, props) =>
  props.location && queryString.parse(props.location.search)

export default combineReducers({
  entities,
  ...require('../views/Analysis.Consumer/state').default
})
