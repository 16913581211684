"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Sticky = exports.Fixed = exports.Absolute = exports.Relative = exports.Position = void 0;

var _emotion = _interopRequireDefault(require("@rebass/components/emotion"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var Position = (0, _emotion.default)('space', 'color', 'zIndex', 'top', 'right', 'bottom', 'left');
exports.Position = Position;
Position.displayName = 'Position';
var Relative = (0, _emotion.default)({
  extend: Position
}, {
  position: 'relative'
});
exports.Relative = Relative;
Relative.displayName = 'Relative';
var Absolute = (0, _emotion.default)({
  extend: Position
}, {
  position: 'absolute'
});
exports.Absolute = Absolute;
Absolute.displayName = 'Absolute';
var Fixed = (0, _emotion.default)({
  extend: Position
}, {
  position: 'fixed'
});
exports.Fixed = Fixed;
Fixed.displayName = 'Fixed';
var Sticky = (0, _emotion.default)({
  extend: Position
}, {
  position: 'sticky'
});
exports.Sticky = Sticky;
Sticky.displayName = 'Sticky';
var _default = Position;
exports.default = _default;