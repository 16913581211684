"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; var ownKeys = Object.keys(source); if (typeof Object.getOwnPropertySymbols === 'function') { ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function (sym) { return Object.getOwnPropertyDescriptor(source, sym).enumerable; })); } ownKeys.forEach(function (key) { _defineProperty(target, key, source[key]); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var brand = {
  purple: '#8147FF',
  dusk: '#5B27A2',
  blue: '#419BFF',
  aqua: '#00EEFF',
  midnight: '#08080C'
};
var base = {
  red: '#F26D76',
  blue: brand.blue,
  green: '#2CD1A7',
  yellow: '#FFC907'
};

var _default = _objectSpread({}, base, brand);

exports.default = _default;