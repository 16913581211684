import { DollarIcon, FanIcon, RepeatIcon, settings as s } from 'App/UI'

const spenderConfig = {
  color: s.colors.green,
  Icon: DollarIcon,
  statName: 'totalSpend',
  name: 'Spender'
}

const repeatConfig = {
  color: s.colors.blue,
  Icon: RepeatIcon,
  statName: 'orderCountInRepeatWindow',
  name: 'Repeat'
}

const fanConfig = {
  color: s.colors.yellow,
  Icon: FanIcon,
  statName: 'reviewAverage',
  name: 'Fan'
}

export const customerConfig = {
  spender: spenderConfig,
  spenders: spenderConfig,
  repeat: repeatConfig,
  repeats: repeatConfig,
  fan: fanConfig,
  fans: fanConfig
}
