import React, { Fragment as _ } from 'react'
import { unstable_createResource as createResource } from 'react-cache'
import { Link } from 'react-router-dom'
import { RingLoader } from 'react-spinners'
import dateFns from 'date-fns'
import {
  Box,
  Text,
  Row,
  Col,
  Circle,
  DollarIcon,
  FanIcon,
  Modal,
  RepeatIcon,
  settings as s,
  Button
} from 'App/UI'
import { api, selectQuery } from 'App/state'
import { InfoIcon } from 'App/shared/Icons'
import { Img } from 'App/shared/Img'
import { makeGradientCss } from 'App/style/utils'

const toPrice = num => `$${Math.round(Number(num))}`

const imgSrc = {
  reviews:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Freview.svg?alt=media&token=a01f8246-de84-4f96-9720-733ef6182659',
  poorReviews:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Fpoor-reviews.svg?alt=media&token=f86aa7e8-3a5e-4adb-8ece-1daaaca0db7b',
  star:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Fstar-wreath.svg?alt=media&token=20f3d879-4284-4c6b-a488-c7b2fb81a7af',
  protractor:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Fprotractor.svg?alt=media&token=67ac8961-4e7f-4959-89f9-b5b017554474',
  no3p:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Fno-3p.svg?alt=media&token=6d5491ca-8ce7-42f6-9400-207dc0ac87e0',
  piggy:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Fpiggy-bank.svg?alt=media&token=ff794b1e-f106-40ae-9edd-9c599e20ab69',
  smartphone:
    'https://firebasestorage.googleapis.com/v0/b/boostly-live.appspot.com/o/assets%2Fimages%2Forder-smartphone.svg?alt=media&token=fd71dbf6-674f-4aa9-b368-fa9598d400b1'
}

const Gradient = ({ startHex, endHex, children }) => (
  <Box css={makeGradientCss({ startHex, endHex })}>{children}</Box>
)

const SectionHead = ({ title }) => (
  <Text.title size={5} color="white" py={3}>
    {title}
  </Text.title>
)
const SectionBody = ({ children }) => (
  <Box maxWidth="400px" m="0 auto">
    {children}
  </Box>
)
const Section = ({ title, gradient, children }) => (
  <Gradient startHex={gradient.from} endHex={gradient.to}>
    <React.Suspense
      fallback={
        <Box px={2} py={2} textAlign="center" height={500}>
          {children({
            SectionHead: () => <SectionHead title={title} />,
            SectionBody: () => (
              <Row x pt={4}>
                <RingLoader color={s.colors.aqua} />
              </Row>
            )
          })}
        </Box>
      }
      maxDuration={1000}
    >
      <Box px={2} pt={2} pb={4} textAlign="center" minHeight={200}>
        {children({
          SectionHead: () => <SectionHead title={title} />,
          SectionBody
        })}
      </Box>
    </React.Suspense>
  </Gradient>
)

const BlockPair = ({ children }) => (
  <Row x y space="between" py={4}>
    <Row w={1 / 2} x y>
      {children[0]}
    </Row>
    <Row w={1 / 2} x y>
      {children[1]}
    </Row>
  </Row>
)

const TextStack = ({ children }) => (
  <Box textAlign="center">
    <Text.title color="white" size={children[0].size || 6}>
      {children[0].copy}
    </Text.title>
    <Text.title color="white" size={children[1].size || 2}>
      {children[1].copy}
    </Text.title>
  </Box>
)

const orderAnalysisResource = createResource(
  ({ locId, orgId, period }) =>
    api({
      method: 'POST',
      endpoint: 'analysis/orders',
      data: {
        locId,
        orgId,
        period
      }
    }),
  obj => JSON.stringify(obj)
)

const OrderAnalysis = ({ params }) => {
  let stats = orderAnalysisResource.read(params)
  const [showModal, toggleModalState] = React.useState(false)

  return (
    <React.Fragment>
      <BlockPair>
        <TextStack>
          {{ copy: toPrice(stats.totalRevenue) }}
          {{ copy: 'Order Revenue' }}
        </TextStack>
        <React.Suspense>
          <Img width="100px" src={imgSrc.piggy} />
        </React.Suspense>
      </BlockPair>
      <BlockPair>
        <React.Suspense>
          <Img height="100px" src={imgSrc.smartphone} />
        </React.Suspense>
        <TextStack>
          {{ copy: stats.orderCount }}
          {{ copy: 'Orders Placed' }}
        </TextStack>
      </BlockPair>

      <BlockPair>
        <TextStack>
          {{ copy: toPrice(stats.averageOrderSize) }}
          {{ copy: 'Avg. Order Size' }}
        </TextStack>
        <React.Suspense>
          <Img height="80px" src={imgSrc.protractor} />
        </React.Suspense>
      </BlockPair>

      <BlockPair>
        <React.Suspense>
          <Img height="80px" src={imgSrc.no3p} />
        </React.Suspense>
        <Col x>
          <TextStack>
            {{ copy: toPrice(stats.hypotheticalCommission) }}
            {{
              copy:
                'Money that would have been taken by a 3rd party delivery service as commission'
            }}
          </TextStack>
          <Box
            pt={3}
            onClick={() => toggleModalState(true)}
            css={`
              cursor: pointer;
            `}
          >
            <InfoIcon />
          </Box>
        </Col>
      </BlockPair>
      <Modal isOpen={showModal} onRequestClose={() => toggleModalState(false)}>
        {({ Close, Jewel, Container, ContentContainer }) => (
          <Container>
            <Close />
            <ContentContainer>
              <Jewel bg={s.colors.blue}>
                <React.Suspense>
                  <Img height="70px" src={imgSrc.no3p} />
                </React.Suspense>
              </Jewel>
              <Box textAlign="center" mt={2}>
                <Text.title>What does this mean?</Text.title>
                <Text.p pt={1} size="14px">
                  This is how much money you would have paid in commission to a
                  3rd party delivery service for the same order volume and
                  therefore how much you saved using Boostly.
                </Text.p>
                <Box py={2} />
                <Text.title>How do we figure?</Text.title>
                <Text.p pt={1} size="14px">
                  We got this number by averaging the amount of money 3rd party
                  ordering companies steal from your wallet like little bandit
                  thiefsies. Typically this is in the neighborhood of 30% per
                  order.
                </Text.p>
                <Box pt={3} pb={1} m="0 auto" w="150px">
                  <Button.two onClick={() => toggleModalState(false)}>
                    Got it 👌
                  </Button.two>
                </Box>
              </Box>
            </ContentContainer>
          </Container>
        )}
      </Modal>
    </React.Fragment>
  )
}

const customerAnalysisResource = createResource(
  ({ repeatThreshold, spendThreshold, ...rest }) =>
    api({
      method: 'POST',
      endpoint: 'analysis/consumers',
      data: {
        ...rest,
        orderCountThresholds: [repeatThreshold],
        totalSpendThresholds: [spendThreshold]
      }
    }),
  obj => JSON.stringify(obj)
)
const CustomerAnalysis = ({ params, thresholds }) => {
  let { totals } = customerAnalysisResource.read({ ...params, ...thresholds })
  // loading state
  // orderAnalysis {totalRevenue, orderCount, avgSize, hypotheticalCommission}
  return (
    <Box>
      <BlockPair>
        <Circle size="giant" bg={s.colors.green}>
          <DollarIcon color="white" size={70} />
        </Circle>
        <TextStack>
          {{ copy: totals.spenders.count }}
          {{
            copy: `Customers who spent $${
              thresholds.spendThreshold
            } or more with you this period of time`
          }}
        </TextStack>
      </BlockPair>
      <BlockPair>
        <TextStack>
          {{ copy: totals.repeats.count }}
          {{
            copy: `Customers returning within this time period who have ordered ${
              thresholds.repeatThreshold
            } or more times in the last ${thresholds.daysInRepeatWindow} days`
          }}
        </TextStack>
        <Circle size="giant" bg={s.colors.blue}>
          <RepeatIcon color="white" size={70} />
        </Circle>
      </BlockPair>
      <BlockPair>
        <Circle size="giant" bg={s.colors.yellow}>
          <FanIcon color="white" size={70} />
        </Circle>
        <TextStack>
          {{ copy: totals.fans.count }}
          {{
            copy: `Customers who rated you a ${
              thresholds.fanThreshold
            } or higher on a scale from 1-10`
          }}
        </TextStack>
      </BlockPair>
    </Box>
  )
}

const reviewAnalysisResource = createResource(
  data =>
    api({
      method: 'POST',
      endpoint: 'analysis/reviews',
      data
    }),
  obj => JSON.stringify(obj)
)
const ReviewAnalysis = ({ params }) => {
  let stats = reviewAnalysisResource.read(params)
  // loading state
  return (
    <React.Fragment>
      <BlockPair>
        <React.Suspense>
          <Img width="100px" src={imgSrc.reviews} />
        </React.Suspense>
        <TextStack>
          {{ copy: stats.totalReviewCount }}
          {{ copy: 'Customer experience reviews obtained' }}
        </TextStack>
      </BlockPair>
      <BlockPair>
        <TextStack>
          {{ copy: stats.fiveStarCount }}
          {{ copy: 'Customers sent to leave a five Star Review' }}
        </TextStack>
        <React.Suspense>
          <Img width="120px" src={imgSrc.star} />
        </React.Suspense>
      </BlockPair>

      <BlockPair>
        <React.Suspense>
          <Img height="150px" src={imgSrc.poorReviews} />
        </React.Suspense>
        <TextStack>
          {{ copy: stats.poorReviewCount }}
          {{ copy: 'Poor experiences turned into feedback' }}
        </TextStack>
      </BlockPair>
    </React.Fragment>
  )
}

// <Box maxWidth="85%" m="0 auto" mb={4}>
//   <Explainer>
//     Studies have found that 20 additional customers visit a restaurant for
//     every 5 star review it has.
//   </Explainer>
// </Box>
// <Box maxWidth="90%" m="0 auto" mb={4}>
//   <Explainer>
//     Studies estimate that negative reviews do bad things to your colon and
//     your colon is a super cancer trap.
//   </Explainer>
// </Box>

const Explainer = ({ children }) => (
  <Box w="100%" borderRadius="50px" bg={s.colors.blue} p={3}>
    <Text.p color="white">{children}</Text.p>
  </Box>
)

const required = ['type', 'month', 'year', 'day']
const isMissingParams = params => required.some(key => !params[key])
const makeStartDate = period =>
  new Date(`${period.month}, ${period.day} ${period.year}`)
const SummaryAnalysis = props => {
  const query = selectQuery({}, props)
  if (isMissingParams(query)) {
    return <MissingUrlParamsView />
  }

  const { locId, orgId } = props.match.params
  const startDate = makeStartDate(query)
  const period = {
    type: query.type,
    startDate
  }

  const params = {
    locId,
    orgId,
    period
  }
  const getEndDate = periodTypeToDateFns[period.type].end
  const [start, end] = [startDate, getEndDate(startDate)].map(date =>
    dateFns.format(date, 'MMM Do, YYYY')
  )

  return (
    <_>
      <Header>
        <Text.title size={3} color={'white'}>
          Boostly Summary Report
        </Text.title>
        <Text.title color="white">{start + ' - ' + end}</Text.title>
      </Header>
      <Section
        title="Orders"
        gradient={{ to: s.colors.red, from: s.colors.dusk }}
      >
        {({ SectionHead, SectionBody }) => (
          <_>
            <Box pt={'45px'}>
              <SectionHead />
            </Box>
            <SectionBody>
              <OrderAnalysis params={params} />
            </SectionBody>
          </_>
        )}
      </Section>

      <Section
        title="Reviews"
        gradient={{ to: s.colors.purple, from: s.colors.midnight }}
      >
        {({ SectionHead, SectionBody }) => (
          <_>
            <SectionHead />
            <SectionBody>
              <ReviewAnalysis params={params} />
            </SectionBody>
          </_>
        )}
      </Section>
      <Section
        title="Customers"
        gradient={{ to: s.colors.aqua, from: s.colors.dusk }}
      >
        {({ SectionHead, SectionBody }) => (
          <_>
            <SectionHead />
            <SectionBody>
              <CustomerAnalysis
                params={params}
                thresholds={{
                  daysInRepeatWindow: query.daysInRepeatWindow || 90,
                  spendThreshold: query.spendThreshold || 50,
                  repeatThreshold: query.repeatThreshold || 2,
                  criticThreshold: query.criticThreshold || 4,
                  fanThreshold: query.fanThreshold || 9
                }}
              />
              <Box p={3} mt={2}>
                <Link
                  to={`/analysis/customers/${orgId}/${locId}/${
                    props.location.search
                  }`}
                >
                  <Button>View These Customers</Button>
                </Link>
              </Box>
            </SectionBody>
          </_>
        )}
      </Section>
    </_>
  )
}

export default SummaryAnalysis

const h = {
  bottom: -60,
  height: 85
}
const Header = ({ children }) => (
  <Box bg={s.colors.purple} position="relative">
    <Row x m={0} textAlign="center" pt={3}>
      <Box css={'z-index: 1'}>{children}</Box>
    </Row>
    <Box
      position="absolute"
      height={h.height}
      w="100%"
      bottom={h.bottom + 'px'}
      align="center"
      backgroundImage={`url(${require('./bubble-heading.svg')})`}
      backgroundPosition="top right"
      backgroundRepeat="no-repeat"
    />
    <Box
      position="absolute"
      height={h.height}
      w="100%"
      bottom={h.bottom + 'px'}
      align="center"
      right="399px"
      backgroundImage={`url(${require('./bubble-heading-plain.svg')})`}
      backgroundPosition="top right"
      backgroundRepeat="repeat-x"
    />
  </Box>
)

const MissingUrlParamsView = () => (
  <Gradient startHex={s.colors.midnight} endHex={s.colors.purple}>
    <Col h="100%" x pt={'10%'}>
      <Box
        bg="white"
        p={3}
        maxWidth={'250px'}
        textAlign="center"
        borderRadius={'25px'}
      >
        <Text.title color={s.colors.red}>
          We have an uhoh. This link is missing one or more required time period
          parameters
        </Text.title>
      </Box>
    </Col>
  </Gradient>
)

const periodTypeToDateFns = {
  monthly: {
    start: dateFns.startOfMonth,
    end: dateFns.endOfMonth,
    prior: dateFns.subMonths
  },
  weekly: {
    start: date => dateFns.subDays(date, 7),
    end: date => dateFns.addDays(date, 7)
  },
  daily: {
    start: dateFns.startOfDay,
    end: dateFns.endOfDay
  }
}
