import React, { Component } from 'react'
import pt from 'prop-types'
import { findDOMNode } from 'react-dom'

class ContentEditable extends Component {
  static propTypes = {
    content: pt.any,
    onChange: pt.func.isRequired,
    style: pt.object,
    className: pt.string,
    getRef: pt.func
  }

  shouldComponentUpdate = nextProps => {
    return nextProps.content !== findDOMNode(this).innerHTML
  }
  componentDidMount = () => {
    console.log('mounting!')
  }
  /**
   * 1. enforce updates that come from parent props.
   */
  componentDidUpdate = () => {
    const { content } = this.props
    if (content !== findDOMNode(this).innerHTML) {
      /* [1] */
      findDOMNode(this).innerHTML = content || ''
      this.lastHtml = content
    }
  }

  emitChange = () => {
    const { onChange } = this.props
    const html = findDOMNode(this).innerHTML
    if (onChange && html !== this.lastHtml) {
      onChange({
        target: {
          value: html
        }
      })
    }
    this.lastHtml = html
  }

  onPaste = e => {
    e.preventDefault()

    // get text representation of clipboard
    const text = e.clipboardData.getData('text/plain')

    // insert text manually
    document.execCommand(
      'insertHTML',
      false,
      text.replace(/(\r\n|\n|\r)/gm, '')
    )

    this.emitChange()
  }

  onKeyPress = e => {
    if (e.keyCode === 13) {
      e.preventDefault()
    }
  }

  render = () => {
    const { style, className, content, getRef } = this.props
    return (
      <div
        ref={getRef}
        onKeyDown={this.onKeyPress}
        style={style}
        className={className}
        onInput={this.emitChange}
        onBlur={this.emitChange}
        onPaste={this.onPaste}
        contentEditable
        dangerouslySetInnerHTML={{ __html: content || '' }}
      />
    )
  }
}

export default ContentEditable
