"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lightening = lightening;
exports.opacity = exports.hexToRgb = exports.createPlaceholderStyles = exports.pxToEm = void 0;

var _settings = require("../settings");

var pxToEm = function pxToEm(px) {
  return "".concat(px / _settings.baseFontSize, "em");
};

exports.pxToEm = pxToEm;

var createPlaceholderStyles = function createPlaceholderStyles(props) {
  return {
    '::-webkit-input-placeholder': props,
    '::-moz-placeholder': props,
    ':-ms-input-placeholder': props,
    ':-moz-placeholder': props
  };
}; // taken from https://github.com/scottcorgan/hex-to-rgb


exports.createPlaceholderStyles = createPlaceholderStyles;

var hexToRgb = function hexToRgb(hex) {
  if (hex.charAt && hex.charAt(0) === '#') {
    hex = removeHash(hex);
  }

  if (hex.length === 3) {
    hex = expand(hex);
  }

  var bigint = parseInt(hex, 16);
  var r = bigint >> 16 & 255;
  var g = bigint >> 8 & 255;
  var b = bigint & 255;
  return [r, g, b];
};

exports.hexToRgb = hexToRgb;

function removeHash(hex) {
  var arr = hex.split('');
  arr.shift();
  return arr.join('');
}

function expand(hex) {
  return hex.split('').reduce(function (accum, value) {
    return accum.concat([value, value]);
  }, []).join('');
}

var opacity = function opacity(hex, _opacity) {
  var rgb = hexToRgb(hex);
  return "rgba(".concat(rgb[0], ", ").concat(rgb[1], ", ").concat(rgb[2], ", ").concat(_opacity, ")");
}; // https://css-tricks.com/snippets/javascript/lighten-darken-color/


exports.opacity = opacity;

function lightening(col, amt) {
  var usePound = false;

  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;else if (r < 0) r = 0;
  var b = (num >> 8 & 0x00FF) + amt;
  if (b > 255) b = 255;else if (b < 0) b = 0;
  var g = (num & 0x0000FF) + amt;
  if (g > 255) g = 255;else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | b << 8 | r << 16).toString(16);
}