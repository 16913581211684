import { createReducer, createAsyncActions as aa } from 'state/utils'
import { api, callStore } from 'App/state'

const makeStartDate = period => new Date(`${period.month}, 1 ${period.year}`)

// "locId": "gbnb9ytJ48zt2z9oXSmD",
//   "period": { "type": "month", "startDate": "Tue May 01 2018 00:00:00 GMT-0600 (Mountain Daylight Time)" },
//   "orderCountThresholds": [ 2 ],
//   "totalSpendThresholds": [ 50, 100 ],
//   "criticThreshold": 4,
//   "fanThreshold": 8
const GET_CUSTOMER_ANALYSIS_ACTIONS = aa('GET_CUSTOMER_ANALYSIS')
export const loadCustomerAnalysis = ({
  spendThreshold,
  repeatThreshold,
  period,
  locId,
  orgId
}) => dispatch =>
  api({
    method: 'POST',
    endpoint: 'analysis/consumers',
    data: {
      locId,
      period: {
        ...period,
        startDate: makeStartDate(period)
      },
      orgId,
      orderCountThresholds: [Number(repeatThreshold)],
      totalSpendThresholds: [
        Number(spendThreshold),
        Number(spendThreshold) + 50
      ],
      criticThreshold: 4,
      fanThreshold: 9
    }
  }).then(response => {
    dispatch({
      type: GET_CUSTOMER_ANALYSIS_ACTIONS[1],
      payload: {
        analysis: response
      },
      meta: {
        locId
      }
    })
  })

const LOAD_LOCATION_DETAILS_ACTIONS = aa('LOAD_LOCATION_DETAILS')
export const loadLocationDetails = ({ orgId, locId }) =>
  callStore({
    types: LOAD_LOCATION_DETAILS_ACTIONS,
    schema: { name: 'locations' },
    query: {
      method: 'get',
      collection: 'orgs',
      doc: orgId,
      subcollections: [{ collection: 'locations', doc: locId }]
    }
  })

const makePeriodId = period => {
  return period.type === 'monthly'
    ? `${period.type}::${period.month}/${period.year}`
    : `${period.type}::${period.day}/${period.day}/${period.year}`
}

const LISTEN_FOR_INTERACTIONS = aa('LISTEN_FOR_INTERACTIONS')
export const listenForInteractions = ({ orgId, locId, period }) =>
  callStore({
    types: LISTEN_FOR_INTERACTIONS,
    schema: { name: 'interactions' },
    meta: { locId },
    query: {
      method: 'onSnapshot',
      collection: 'interactions',
      where: [
        ['orgId', '==', orgId],
        ['locationId', '==', locId],
        ['meta.periodId', '>=', makePeriodId(period)]
      ]
    }
  })

export const sendAppreciationText = ({
  consumerId,
  locId,
  orgId,
  textMessage,
  includedPromo,
  period,
  interactionToUpdate
}) =>
  api({
    method: 'POST',
    endpoint: 'interactions/appreciation/sendText',
    data: {
      consumerId,
      locationId: locId,
      orgId,
      textMessage,
      includedPromo,
      interactionToUpdate,
      periodId: makePeriodId(period)
    }
  })

export const declineAppreciationAction = ({
  consumerId,
  locId,
  orgId,
  period
}) =>
  api({
    method: 'POST',
    endpoint: 'interactions/appreciation/decline',
    data: {
      consumerId,
      locationId: locId,
      orgId,
      periodId: makePeriodId(period)
    }
  })

export default {
  customerAnalysis: createReducer(
    {},
    {
      [GET_CUSTOMER_ANALYSIS_ACTIONS[1]]: (state, { payload, meta }) => ({
        ...state,
        [meta.locId]: payload.analysis
      })
    }
  ),
  customerInteractions: createReducer(
    {},
    {
      [LISTEN_FOR_INTERACTIONS[1]]: (state, { payload, meta }) => ({
        ...state,
        [meta.locId]: payload.ids
      })
    }
  )
}
