"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.Col = exports.Row = void 0;

var _emotion = _interopRequireDefault(require("@rebass/components/emotion"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getWidth = function getWidth(n) {
  return !num(n) || n > 1 ? px(n) : n * 100 + '%';
};

var num = function num(n) {
  return typeof n === 'number' && !isNaN(n);
};

var px = function px(n) {
  return num(n) ? n + 'px' : n;
};

var apis = [function (props) {
  return {
    height: px(props.h)
  };
}, function (props) {
  return {
    width: getWidth(props.w)
  };
}, function (props) {
  return {
    cursor: props.cursor
  };
}, // core
'space', 'width', 'fontSize', 'textColor', 'bgColor', // typography
'textAlign', // layout
'display', 'maxWidth', 'minWidth', 'height', 'maxHeight', 'minHeight', 'size', 'ratio', 'verticalAlign', // borders
'borderTop', 'borderRight', 'borderBottom', 'borderLeft', 'borders', 'borderColor', 'borderRadius', // misc
'boxShadow', 'opacity', 'background', 'backgroundImage', 'backgroundSize', 'backgroundPosition', 'backgroundRepeat', // position
'position', 'zIndex', 'top', 'right', 'bottom', 'left'];

var justifyLogic = function justifyLogic(key) {
  return function (p) {
    return p[key] && {
      justifyContent: p[key] === 'end' ? 'flex-end' : 'center'
    };
  };
};

var alignLogic = function alignLogic(key) {
  return function (p) {
    return p[key] && {
      alignItems: p[key] === 'end' ? 'flex-end' : p.x === 'stretch' ? 'stretch' : 'center'
    };
  };
};

var row = {
  x: justifyLogic('x'),
  y: alignLogic('y'),
  space: function space(p) {
    return p.space && {
      justifyContent: "space-".concat(p.space)
    };
  },
  reverse: function reverse(p) {
    return p.reverse && {
      flexDirection: 'row-reverse'
    };
  }
};
var col = {
  x: alignLogic('x'),
  y: justifyLogic('y'),
  space: function space(p) {
    return p.space && {
      alignItems: "space-".concat(p.space)
    };
  },
  reverse: function reverse(p) {
    return p.reverse && {
      flexDirection: 'column-reverse'
    };
  }
};

var wrap = function wrap(p) {
  return p.wrap && {
    flexWrap: p.wrap === 'reverse' ? 'wrap-reverse' : 'wrap'
  };
};

var blacklist = ['w', 'h', 'x', 'y', 'reverse', 'wrap', 'space'];

var Layout = _emotion.default.apply(void 0, apis);

var Row = _emotion.default.apply(void 0, [{
  extend: (0, _emotion.default)({
    blacklist: blacklist
  })
}, {
  display: 'flex'
}].concat(apis, [row.x, row.y, row.space, row.reverse, wrap]));

exports.Row = Row;
Layout.row = Row;
Layout.row.displayName = 'Row';

var Col = _emotion.default.apply(void 0, [{
  extend: (0, _emotion.default)({
    blacklist: blacklist
  })
}, {
  display: 'flex',
  flexDirection: 'column'
}].concat(apis, [col.x, col.y, col.space, col.reverse, wrap]));

exports.Col = Col;
Layout.col = Col;
Layout.col.displayName = 'Column';
var _default = Layout;
exports.default = _default;